import React, { useState } from 'react';
import Card from '../Card';
import { Heading1 } from '../../components/Typography';
import Select from 'react-select';
import Button from '../Button/Button';
import Notification from '../Notification';
import { Input, TextArea, Label } from '../Forms';
import fetch from '../../utils/fetch';
import { navigate } from 'gatsby';
import Table from './Table';
import LoadingSpinner from '../LoadingSpinner';
import Flexbox from '../Flexbox';

const options = [
    { value: 'General query', label: 'General query' },
    { value: 'Payment query', label: 'Payment query' },
    { value: 'Offer of Arrangement', label: 'Offer of Arrangement' },
    { value: 'Proof of Residency', label: 'Proof of Residency' },
    { value: 'Financial Statement', label: 'Financial Statement' },
    { value: 'Complaint', label: 'Complaint' },
];

const ContactForm = ({ userDetails }) => {
    const [selected, setSelected] = useState(options[0]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [error,setError] = useState();

    function handleChange(option) {
        setSelected(option);
    }

    function handleFieldChange(e) {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    function handleFileChange(e) {
        e.persist();

        setFormData((prevState) => ({
            ...prevState,
            attachments: e.target.files,
        }));
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const data = new FormData();
        data.append('reason', selected.value);

        Object.entries(formData).forEach(([key, value]) => {
            if (key === 'attachments') {
                const fileValues = Object.values(value);
                fileValues.forEach((file) => {
                    data.append('attachments[]', file, file.name);
                });
            } else data.append(key, value);
        });

        try {
            setLoading(true);
            const response = await fetch.post('/api/contact-us', data);
            if (response.status !== 200) {
                navigate('/web-services/form-submit-error', {
                    replace: true,
                });
            }
            if (window) {
                navigate('/web-services/form-submitted', {
                    replace: true,
                });
            }
        } catch (error) {
            setLoading(false);
            if(!error.response){
                return setError('Unable to connect with server. Please try again, if this continues please contact us.');
            }
            if(error.response.status===500){
                return setError('Server error. Please try again, if this continues please contact us.');
            }
            if(error.response.status===413){
                return setError('File size exceeded. If you require this document to be uploaded, please get in touch with us directly.');
            } 
            if(error.response.status===422){
                const errors = error.response.data.errors;
                //Set generic error if none
                if(!errors || Object.values(errors).length===0){
                    return setError('Invalid data provided, please check the form and try again.');
                }

                const errorsValues = Object.values(errors);
                
                //Set the error to the first error provided back from the backend
                return setError(errorsValues[0][0]);
            }

            return setError('Something went wrong. Please try again, if this continues please contact us.');
        }
    }

    return (
        <Card p={40} maxWidth={620}>
            <Heading1 mb={24} fontSize={28} textAlign='center'>
                Contact Form
            </Heading1>
            <Table {...userDetails} />
            <Label htmlFor='reason'>Reason</Label>
            <form id='contact-form' onSubmit={handleSubmit}>
                <Select
                    options={options}
                    onChange={handleChange}
                    value={selected}
                    id='reason'
                    theme={theme}
                    styles={style}
                />
                <Input
                    label='Email'
                    name='email'
                    id='email'
                    type='text'
                    onChange={handleFieldChange}
                    required
                />
                <Input
                    label='Phone Number'
                    name='phone'
                    id='phone'
                    type='text'
                    onChange={handleFieldChange}
                    required
                />
                <Input
                    label='First Name'
                    name='first_name'
                    id='first-name'
                    type='text'
                    onChange={handleFieldChange}
                    required
                />
                <Input
                    label='Last Name'
                    name='surname'
                    id='last-name'
                    type='text'
                    onChange={handleFieldChange}
                    required
                />
                <Input
                    label='Company (optional)'
                    name='company'
                    id='company'
                    type='text'
                    onChange={handleFieldChange}
                />
                <TextArea
                    label='Message'
                    required
                    name='message'
                    onChange={handleFieldChange}
                />

                <Flexbox flexDirection='column' alignItems='start' mt={24}>
                    <Label htmlFor='attachments'>Upload Attachment</Label>
                    <input
                        label='Attach file'
                        name='attachments'
                        id='attachments'
                        type='file'
                        onChange={handleFileChange}
                        style={{ fontSize: '12px', marginTop: '8px' }}
                        multiple
                    />
                </Flexbox>
                { error && <Notification mt='24px'>{error}</Notification> }
            </form>
            <Notification
                backgroundColor='grey'
                color='darkblue'
                borderColor='lightblue'
                padding='.4rem .8rem'
                lineHeight='22px'
                fontSize='16px !important'
            >
                <strong>Note:</strong>&nbsp;If you are a third party, please ensure
                you attach authorisation to contact us on behalf of the debtor.
            </Notification>
            <Button type='submit' width='100%' form='contact-form'>
                {loading ? <LoadingSpinner /> : 'Submit'}
            </Button>
        </Card>
    );
};

const style = {
    control: (base) => ({
        ...base,
        fontSize: '16px',
        marginBottom: '16px',
        height: '40px',
    }),
    menu: (base) => ({
        ...base,
        fontSize: '16px',
    }),
};

const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#f3f3f3',

        // All possible overrides
        primary: '#5d72da',

        neutral0: '#f8f9ff',
        neutral20: '#c2c2c2',
    },
    // Other options you can use
    borderRadius: 4,
});

export default ContactForm;
