import React from 'react';
import SEO from '../components/seo';
import Section from '../components/Section';
import HeroSection from '../components/HeroSection';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import webservices from '../constants/webservices';
import ContactFormComponent from '../components/ContactPageForm/ContactForm';

const ContactForm = ({ location = {} }) => {
  React.useEffect(() => {
    if (!location.state || !location.state.authenticated) {
      navigate('/web-services/login', {
        state: {
          title: webservices.contactPage.title,
          body: webservices.contactPage.description,
          redirectOnLogin: webservices.contactPage.url,
        },
        replace: true,
      });
    }
  }, [location.state]);

  if (!location.state || !location.state.authenticated) return null;

  return (
    <>
      <SEO
        title='Contact Form'
        description='Jacobs are the leading Debt Recovery and Enforcement Services provider in the United Kingdom, with 60 years experience working with local authorities across the country.'
      />
      <HeroSection />
      <Section pt={80} pb={220} backgroundColor='grey'>
        <Container>
          <ContactFormComponent userDetails={location.state.userDetails} />
        </Container>
      </Section>
    </>
  );
};

export default ContactForm;

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1100px;
  margin: 0 auto;
  @media (max-width: 992px) {
    display: block;
  }
`;
